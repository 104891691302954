import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { JobsiteService } from '../services/jobsite/jobsite.service';
import { Notification } from '../types/notification.types';
import { ConfigurationService } from './configuration.service';
import { UserService } from './user/user.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private httpClient = inject(HttpClient);
	private jobsiteService = inject(JobsiteService);
	private configurationService = inject(ConfigurationService);
	private userService = inject(UserService);

	public notifications$: Observable<Notification[]> = this.userService.isLoggedIn$.pipe(
		filter((isLoggedIn) => isLoggedIn),
		switchMap(() => this.configurationService.featureToggles$),
		map((featureToggles) => featureToggles.other.areNotificationsEnabled),
		distinctUntilChanged(), // Proceed only if areNotificationsEnabled has changed
		filter((areNotificationsEnabled) => areNotificationsEnabled),
		switchMap(() => this.jobsiteService.favoriteJobsites$),
		map((favoriteJobsites) => favoriteJobsites.map((jobsite) => jobsite.Id)),
		switchMap((jobsiteIds) =>
			jobsiteIds.length > 0
				? this.httpClient.post<Notification[]>(`/webapi/auth/notificationsforjobsites`, jobsiteIds)
				: of([]),
		),
		shareReplay(1),
	);
}
